// Layout.js
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { Box, CssBaseline, AppBar, Card, CardContent } from "@mui/material";
import TitleBar from "./TitleBar"; // Adjust the import according to your file structure

const Layout = ({ children }) => {
  return (
    <CssBaseline>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ height: 64 }}>
          <Toolbar sx={{ minHeight: 64 }}>
            <TitleBar />
          </Toolbar>
        </AppBar>
        <Card
          sx={{
            margin: 2,
            marginTop: "84px",
            height:
              "calc(100vh - 104px)" /* Adjust height to fit within viewport */,
          }}
        >
          <CardContent sx={{ overflowY: "auto", height: "100%" }}>
            {children}
          </CardContent>
        </Card>
      </Box>
    </CssBaseline>
  );
};

export default Layout;
