import React from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CampaignManagement = () => {
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();

  const handleCreateCampaign = () => {
    navigate("/create-campaign");
  };

  return (
    <Container>
      <Typography variant="h4" style={{ margin: "20px 0" }}>
        Campaign Management
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h6">Active Campaigns</Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: 20 }}
              onClick={handleCreateCampaign}
            >
              Create Campaign
            </Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{/* Map over campaigns and display here */}</TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h6">Archived Campaigns</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Map over archived campaigns and display here */}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CampaignManagement;
