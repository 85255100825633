import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Alert,
} from "@mui/material";
import invitationService from "./invitationService";

const InvitedUserManagement = ({ companyId }) => {
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteData, setInviteData] = useState({ email: "" });
  const [inviteError, setInviteError] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const invitedUsers = await invitationService.invitedUsers(companyId);
        setInvitedUsers(invitedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Error fetching users");
      }
    };

    fetchUsers();
  }, [companyId]);

  const handleOpenInvite = () => {
    setOpenInvite(true);
  };

  const handleCloseInvite = () => {
    setOpenInvite(false);
    setInviteData({ email: "" });
    setInviteError("");
  };

  const handleInviteChange = (e) => {
    const { name, value } = e.target;
    setInviteData({ ...inviteData, [name]: value });
  };

  const handleInviteSubmit = async () => {
    try {
      const new_invite = await invitationService.inviteUser(inviteData.email);
      console.log(`Invite sent to: ${inviteData.email}`);
      setInvitedUsers([...invitedUsers, new_invite]);
      handleCloseInvite();
    } catch (error) {
      console.error("Error sending invite:", error);
      setInviteError("Failed to send invite. Please try again.");
    }
  };

  const handleCancelInvite = async (id) => {
    try {
      await invitationService.cancelInvite(id); // Assuming tenantService has a cancelInvite method
      setInvitedUsers(invitedUsers.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Error cancelling invite:", error);
      setError("Failed to cancel invite. Please try again.");
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Invited Users
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Button
        variant="contained"
        color="secondary"
        style={{ marginBottom: 20 }}
        onClick={handleOpenInvite}
      >
        Invite User
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitedUsers.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Button
                  color="secondary"
                  onClick={() => handleCancelInvite(user.id)}
                >
                  Cancel Invite
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={openInvite} onClose={handleCloseInvite}>
        <DialogTitle>Send Invite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address to send an invite.
          </DialogContentText>
          {inviteError && <Alert severity="error">{inviteError}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={inviteData.email}
            onChange={handleInviteChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInvite} color="primary">
            Cancel
          </Button>
          <Button onClick={handleInviteSubmit} color="primary">
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default InvitedUserManagement;
