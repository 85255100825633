import React, { useState } from 'react';
import { TextField, Button, Typography, Card, CardContent } from '@mui/material';

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState({
    username: 'John Doe',
    email: 'john.doe@example.com',
    companyName: 'Example Inc.',
    companyAddress: '1234 Main St, Anytown, USA',
    password: '',
    newPassword: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handlePasswordUpdate = () => {
    // Handle password update logic here
    console.log('Password updated:', userDetails.newPassword);
    // Reset the password fields
    setUserDetails({ ...userDetails, password: '', newPassword: '' });
  };

  return [     
    <Card key="user-profile" sx={{ margin: 2, overflowY: 'auto' }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          User Profile
        </Typography>
        <TextField
          fullWidth
          label="Username"
          name="username"
          value={userDetails.username}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={userDetails.email}
          onChange={handleChange}
          margin="normal"
        />
      </CardContent>
    </Card>,
    <Card key="update-password" sx={{ margin: 2, overflowY: 'auto' }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Update Password
        </Typography>
        <TextField
          fullWidth
          label="Current Password"
          type="password"
          name="password"
          value={userDetails.password}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="New Password"
          type="password"
          name="newPassword"
          value={userDetails.newPassword}
          onChange={handleChange}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handlePasswordUpdate}
          sx={{ mt: 2 }}
        >
          Update Password
        </Button>
      </CardContent>
    </Card>,
  ];
};

export default UserProfile;
