import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import TemplateCard from "./TemplateCard"; // Import the TemplateCard component
import TemplateForm from "./TemplateForm"; // Import the TemplateForm component
import { getOwnedTemplates, deleteTemplate } from "../../services/templateService"; // Import your template services

const TemplateDashboard = () => {
  const [ownedTemplates, setOwnedTemplates] = useState([]); // State to store templates
  const [openForm, setOpenForm] = useState(false); // State to control form dialog visibility

  useEffect(() => {
    // Fetch templates owned by the user on component mount
    getOwnedTemplates().then((response) => setOwnedTemplates(response));
  }, []);

  // Function to open the template form dialog
  const handleOpenForm = () => setOpenForm(true);

  // Function to close the template form dialog
  const handleCloseForm = () => setOpenForm(false);

  // Function to handle the addition of a new template
  const handleAddTemplate = (newTemplate) => {
    setOwnedTemplates([...ownedTemplates, newTemplate]); // Add the new template to the list
    handleCloseForm(); // Close the form after adding a new template
  };

  // Function to handle updating a template
  // Function to handle updating a template
  const handleUpdateTemplate = (updatedTemplate) => {
    setOwnedTemplates((prevTemplates) =>
      prevTemplates.map((template) =>
        template.id === updatedTemplate.id && template.template_type === updatedTemplate.template_type
          ? updatedTemplate // Replace the matching template
          : template // Keep the existing template
      )
    );
  };


  // Function to handle deleting a template
  const handleDeleteTemplate = async (template_type, templateId) => {
    try {
      // Perform the deletion logic here
      await deleteTemplate(template_type, templateId);

      // Filter out the deleted template from the list
      setOwnedTemplates(
        ownedTemplates.filter((template) => template.id !== templateId)
      );
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Templates
      </Typography>

      {/* Button to open the add template form */}
      <Button variant="contained" color="primary" onClick={handleOpenForm}>
        Add Template
      </Button>

      {/* Dialog for Template Form */}
      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        aria-labelledby="add-template-form"
      >
        <DialogTitle>Add New Template</DialogTitle>
        <DialogContent>
          <TemplateForm onAddTemplate={handleAddTemplate} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Display the templates in a grid */}
      <Box mt={3}>
        <Grid container spacing={2}>
          {ownedTemplates.length > 0 ? (
            ownedTemplates.map((template) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={`${template.template_type}-${template.id}`} // Ensure unique keys using both template_type and id
              >
                {/* Use the TemplateCard component for each template */}
                <TemplateCard
                  template={template}
                  onUpdateTemplate={handleUpdateTemplate} // Pass the update handler to the card
                  onDeleteTemplate={handleDeleteTemplate} // Pass the delete handler to the card
                />
              </Grid>
            ))
          ) : (
            <Typography>No templates available.</Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default TemplateDashboard;
