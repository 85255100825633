import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_TENANT_API_URL}/invitations`;

const invitationService = {
  invitedUsers: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/`);
      return response.data;
    } catch (error) {
      throw new Error("Error fetching invited users");
    }
  },
  inviteUser: async (email) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/`,
        { email }
      );
      return response.data;
    } catch (error) {
      throw new Error("Error sending invite");
    }
  },
  cancelInvite: async (inviteId) => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/${inviteId}/deactivate`
      );
      return response.data;
    } catch (error) {
      throw new Error("Error sending invite");
    }
  },
};

export default invitationService;
