import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Grid, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, FormControl, InputLabel, Alert } from '@mui/material';
import applicationService from './questionService';

const ApplicationManagement = () => {
  const [applications, setApplications] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentApplication, setCurrentApplication] = useState(null);
  const [dialogMode, setDialogMode] = useState('create'); // 'create' or 'edit'
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const applicationData = await applicationService.getApplications();
        setApplications(applicationData);
      } catch (error) {
        console.error('Error fetching applications:', error);
        setError('Error fetching applications');
      }
    };

    fetchApplications();
  }, []);

  const handleOpenDialog = (mode, application = null) => {
    setDialogMode(mode);
    setCurrentApplication(application);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentApplication(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentApplication({ ...currentApplication, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (dialogMode === 'create') {
        await applicationService.createApplication(currentApplication);
      } else {
        await applicationService.updateApplication(currentApplication.id, currentApplication);
      }
      setOpenDialog(false);
      const updatedApplications = await applicationService.getApplications();
      setApplications(updatedApplications);
      setError(null);
    } catch (error) {
      console.error('Error saving application:', error);
      setError('Failed to save application. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await applicationService.deleteApplication(id);
      const updatedApplications = await applicationService.getApplications();
      setApplications(updatedApplications);
      setError(null);
    } catch (error) {
      console.error('Error deleting application:', error);
      setError('Failed to delete application. Please try again.');
    }
  };

  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>Application Management</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h6">Applications</Typography>
            <Button variant="contained" color="primary" style={{ marginBottom: 20 }} onClick={() => handleOpenDialog('create')}>
              Add Application
            </Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ display: 'none' }}>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.map((application) => (
                  <TableRow key={application.id}>
                    <TableCell>{application.id}</TableCell>
                    <TableCell>{application.name}</TableCell>
                    <TableCell>{application.status}</TableCell>
                    <TableCell>{application.last_updated}</TableCell>
                    <TableCell>
                      <Button color="primary" style={{ marginRight: 10 }} onClick={() => handleOpenDialog('edit', application)}>Edit</Button>
                      <Button color="secondary" onClick={() => handleDelete(application.id)}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ApplicationManagement;
