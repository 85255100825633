import React from 'react';
import { Container, Typography } from '@mui/material';
import ActiveUserManagement from './ActiveUserManagement';
import InvitedUserManagement from './InvitedUserManagement';

const UserManagement = ({ companyId }) => {
  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>User Management</Typography>
      <ActiveUserManagement companyId={companyId} />
      <InvitedUserManagement companyId={companyId} />
    </Container>
  );
};

export default UserManagement;
