import React from "react";
import { Box, Typography } from "@mui/material";
import OrganizationDetails from "./OrganizationDetails";
import OrganizationMembersPage from "./OrganizationMembersPage";
import OrganizationInvitationsPage from "./OrganizationInvitationsPage";
import { useParams } from "react-router-dom";

const OrganizationProfile = () => {
  const { organization_id } = useParams();

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Organization Profile
      </Typography>

      {/* Adding space between components */}
      <Box mb={3}>
        <OrganizationDetails organization_id={organization_id} />
      </Box>
      <Box mb={3}>
        <OrganizationMembersPage organization_id={organization_id} />
      </Box>
      <Box mb={3}>
        <OrganizationInvitationsPage organization_id={organization_id} />
      </Box>
    </Box>
  );
};

export default OrganizationProfile;
