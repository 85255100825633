import React from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Paper } from "@mui/material";
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div>
      <Container>
        <Typography variant="h4" style={{ margin: '20px 0' }}>Welcome</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 20 }}>
              <Typography variant="h5">Overview</Typography>
              {/* Summary of active campaigns, recent activities, pending tasks */}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;