// src/services/apiClient.js
import axios from 'axios';

class ApiClient {
  constructor(baseURL) {
    this.apiClient = axios.create({
      baseURL: baseURL,
    });

    this.isRefreshing = false;
    this.failedQueue = [];

    this.apiClient.interceptors.request.use(this.addAuthHeader);
    this.apiClient.interceptors.response.use(
      (response) => response,
      (error) => this.handleTokenExpiration(error)
    );
  }

  // Add Authorization header to every request
  addAuthHeader = (config) => {
    const token = localStorage.getItem('id_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };

  // Handle refresh token logic
  handleTokenExpiration = async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to token expiration
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (error.response.data.detail === 'Token has expired') {
        if (this.isRefreshing) {
          return new Promise((resolve, reject) => {
            this.failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = 'Bearer ' + token;
              return this.apiClient(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        this.isRefreshing = true;

        const refreshToken = localStorage.getItem('refresh_token');

        return new Promise((resolve, reject) => {
          axios
            .post(`${this.apiClient.defaults.baseURL}/auth/refresh-token`, { refresh_token: refreshToken })
            .then(({ data }) => {
              const newAccessToken = data.access_token;
              localStorage.setItem('id_token', newAccessToken);
              this.apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
              originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
              this.processQueue(null, newAccessToken);
              resolve(this.apiClient(originalRequest));
            })
            .catch((err) => {
              this.processQueue(err, null);
              localStorage.removeItem('id_token');
              localStorage.removeItem('refresh_token');
              window.location.href = '/login';
              reject(err);
            })
            .finally(() => {
              this.isRefreshing = false;
            });
        });
      }
    }

    return Promise.reject(error);
  };

  // Process the queue of failed requests during token refresh
  processQueue = (error, token = null) => {
    this.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedQueue = [];
  };

  // Expose apiClient instance
  getClient() {
    return this.apiClient;
  }
}

export default ApiClient;
