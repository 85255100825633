import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import tenantService from "../../services/tenantService";
import { UserContext } from "../../contexts/UserContext";

const OrganizationInvitationsPage = ({ organization_id }) => {
  const { user } = useContext(UserContext);
  const [invitations, setInvitations] = useState([]);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newRole, setNewRole] = useState("");
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false); // For adding new invitations
  const [inviteEmail, setInviteEmail] = useState(""); // New invite email
  const [inviteRole, setInviteRole] = useState("Member"); // Default new invite role

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const invitations =
          await tenantService.getOrganizationInvitations(
            organization_id
          );
        setInvitations(invitations);
      } catch (error) {
        console.error("Error fetching invitations:", error);
      }
    };

    fetchInvitations();
  }, [organization_id]);

  const handleEditClick = (invitation) => {
    setSelectedInvitation(invitation);
    setNewRole(invitation.role);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (invitation) => {
    setSelectedInvitation(invitation);
    setDeleteDialogOpen(true);
  };

  const handleEditSave = async () => {
    try {

      const invitationPayload = {
        organization_id: organization_id, // Assuming organization_id is available in scope
        email: inviteEmail,               // Input email from the form
        role: inviteRole,                 // Role from the form, e.g., "Member" or "Admin"
        requested_by: user.email,         // Requesting user's email
        invitation_fulfilled: "False",    // Defaulting to "False"
      };

      await tenantService.updateInvitation(
        organization_id,
        selectedInvitation.id,
        invitationPayload
      );
      setInvitations(
        invitations.map((m) =>
          m.email === selectedInvitation.email ? { ...m, role: newRole } : m
        )
      );
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating invitation role:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await tenantService.deleteInvitation(
        organization_id,
        selectedInvitation.id
      );
      setInvitations(invitations.filter((m) => m.id !== selectedInvitation.id));
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error removing invitation:", error);
    }
  };

  const handleInviteSubmit = async () => {
    try {
      const invitationPayload = {
        organization_id: organization_id, // Assuming organization_id is available in scope
        email: inviteEmail,               // Input email from the form
        role: inviteRole,                 // Role from the form, e.g., "Member" or "Admin"
        requested_by: user.email,         // Requesting user's email
        invitation_fulfilled: "False",    // Defaulting to "False"
      };
  
      const invitation = await tenantService.createInvitation(
        organization_id,
        invitationPayload
      );
  
      // Update the state with the new invitation
      setInvitations([...invitations, invitation]);
  
      // Close the dialog and clear the input fields
      setInviteDialogOpen(false);
      setInviteEmail("");  // Clear the email field
      setInviteRole("Member");  // Reset the role to a default value like "Member"
    } catch (error) {
      console.error("Error inviting member:", error);
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            Invitations
          </Typography>
          <IconButton color="primary" onClick={() => setInviteDialogOpen(true)}>
            <AddIcon /> {/* Button to trigger the new invitation form */}
          </IconButton>
        </Box>
      </CardContent>
      <Divider />
      <CardContent>
        {invitations.map((invitation) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            key={invitation.id}
          >
            <input type="hidden" value={invitation.id} />

            <Box>
              <Typography variant="body2" color="textSecondary">
                {invitation.email}
              </Typography>
              <Typography variant="caption" color="primary">
                Role: {invitation.role}
              </Typography>
            </Box>
            <Box>
              <IconButton
                color="primary"
                onClick={() => handleEditClick(invitation)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => handleDeleteClick(invitation)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </CardContent>

      {/* Invite Member Dialog */}
      <Dialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Invite New Member</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Role</InputLabel>
            <Select
              value={inviteRole}
              onChange={(e) => setInviteRole(e.target.value)}
              label="Role"
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Member">Member</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2 }}>
          <Button onClick={() => setInviteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleInviteSubmit} color="primary" variant="contained">
            Invite
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Role Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Edit Invitation</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Role</InputLabel>
            <Select
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              label="Role"
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Member">Member</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2 }}>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selectedInvitation?.email}'s
            invitation?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default OrganizationInvitationsPage;
