import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import tenantService from "../../services/tenantService";
import { UserContext } from "../../contexts/UserContext";

const OrganizationMembersPage = ({ organization_id }) => {
  const { user } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newRole, setNewRole] = useState("");

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const members = await tenantService.getOrganizationMembers(
          organization_id
        );
        console.log("Fetching members for organization:", members);
        setMembers(members);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, [organization_id]);

  const handleEditClick = (member) => {
    setSelectedMember(member);
    setNewRole(member.role);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = (member) => {
    setSelectedMember(member);
    setDeleteDialogOpen(true);
  };

  const handleEditSave = async () => {
    try {
      await tenantService.updateMemberRole(
        organization_id,
        selectedMember.email,
        newRole
      );
      setMembers(
        members.map((m) =>
          m.email === selectedMember.email ? { ...m, role: newRole } : m
        )
      );
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating member role:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await tenantService.removeMember(
        organization_id,
        selectedMember.id
      );
      setMembers(members.filter((m) => m.id !== selectedMember.id));
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" gutterBottom>
            Members
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardContent>
        {members.map((member) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            key={member.id}
          >
            <input type="hidden" value={member.id} />

            <Box>
              <Typography variant="subtitle1" fontWeight="medium">
                {member.first_name} {member.last_name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {member.email}
              </Typography>
              <Typography variant="caption" color="primary">
                Role: {member.role}
              </Typography>
            </Box>
            {member.email !== user.email && (
              <Box>
                <IconButton
                  color="primary"
                  onClick={() => handleEditClick(member)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => handleDeleteClick(member)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        ))}
      </CardContent>

      {/* Edit Role Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Member Role</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Member">Member</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selectedMember?.name} from the
            organization?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default OrganizationMembersPage;
