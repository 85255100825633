import React, { createContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
export const UserContext = createContext(); // Create a context object

const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
const clientId = process.env.REACT_APP_CLIENT_ID;
const cognitoRedirectURI = process.env.REACT_APP_REDIRECT_URI;

const getRedirectUri = () => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:3000/oauth2/idpresponse";
  }
  return cognitoRedirectURI;
};

const getLogoutUri = () => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:3000";
  }
  return "https://app.mentoring-fusion.com";
};

export const UserProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false); // Create loggedIn state
  const [user, setUser] = useState(null); // Create user state
  const [redirectUri, setRedirectUri] = useState(getRedirectUri());

  useEffect(() => {
    // Check if user is already logged in by checking tokens
    const token = localStorage.getItem("id_token");
    console.log("token", token);
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setLoggedIn(true);
        setUser({
          username: decodedToken["cognito:username"],
          email: decodedToken.email,
          // Add other user attributes if necessary
        });
        fetchUserInfo(token, decodedToken["cognito:username"]);
      } catch (error) {
        console.error("Error decoding token:", error);
        setLoggedIn(false);
        setUser(null);
      }
    }
  }, []);

  const fetchUserInfo = async (token, username) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TENANT_API_URL}/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("response", response);
      // Assuming the response contains user information and permissions
      const user = {
        email: response.data.email,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        id: response.data.id,
        username: username,
        token: token,
      };
      
      setUser(user);
    } catch (error) {
      console.error("Error fetching user info", error);
    }
  };
  const login = () => {
    const cognitoLoginUrl = `${cognitoDomain}/login?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = cognitoLoginUrl;
  };

  const logout = () => {
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setLoggedIn(false);
    setUser(null);

    const logoutUri = getLogoutUri();
    const cognitoLogoutUrl = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${logoutUri}`;
    window.location.href = cognitoLogoutUrl;
  };

  return (
    <UserContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        redirectUri,
        setRedirectUri,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
