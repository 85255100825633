import React, { useState, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { Box, Typography, TextField, Button, Container, Paper, Grid } from "@mui/material";
import { UserContext } from "../../contexts/UserContext";
import jwtDecode from "jwt-decode";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(Array(6).fill(""));
  const { setLoggedIn, setUser } = useContext(UserContext); // Get context methods
  const navigate = useNavigate();
  const location = useLocation();

  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
  };

  const userPool = new CognitoUserPool(poolData);

  const handleLogin = (e) => {
    e.preventDefault();

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log("login success");
        
        // Save tokens to localStorage
        localStorage.setItem("id_token", result.getIdToken().getJwtToken());
        localStorage.setItem(
          "access_token",
          result.getAccessToken().getJwtToken()
        );
        localStorage.setItem(
          "refresh_token",
          result.getRefreshToken().getToken()
        );

        // Decode the token to extract user information
        const decodedToken = jwtDecode(result.getIdToken().getJwtToken());

        // Update context
        setLoggedIn(true);
        const user = {
          username: cognitoUser.getUsername(),
          email: decodedToken.email,
          token: result.getIdToken().getJwtToken(),
        };
        setUser(user);

        // Trigger fetchUserInfo to update user context with more data
        fetchUserInfo(user.token, user.username);

        navigate("/");
      },
      onFailure: (err) => {
        if (err.code === "UserNotConfirmedException") {
          setError("Please verify your email to log in.");
          setShowConfirmation(true); // Show confirmation code input
        } else {
          setError(err.message || "Login failed.");
        }
      },
    });
  };

  const fetchUserInfo = async (token, username) => {
    console.log("token:" + token);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TENANT_API_URL}/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("response", response);
      // Assuming the response contains user information and permissions
      const user = {
        email: response.data.email,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        id: response.data.id,
        username: username,
        token: token,
      };

      setUser(user);
    } catch (error) {
      console.error("Error fetching user info", error);
    }
  };

  const handleConfirmationCodeChange = (index, value) => {
    const newCode = [...confirmationCode];
    newCode[index] = value;
    setConfirmationCode(newCode);
  };

  const handleConfirm = () => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const code = confirmationCode.join("");

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
      setShowConfirmation(false);
      navigate("/");
    });
  };

  const handleResendEmail = () => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
      console.log("Confirmation code resent successfully");
      setError("Verification code resent. Please check your email.");
    });
  };

  return (
    <Container>
      <Box sx={styles.pageContainer}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>

          {location.state?.successMessage && (
            <Box sx={styles.successMessage}>
              <Typography color="success" variant="body1">
                {location.state.successMessage}
              </Typography>
              <Button onClick={handleResendEmail} sx={styles.resendButton}>
                Resend Code
              </Button>
            </Box>
          )}

          <form onSubmit={handleLogin}>
            <TextField
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              margin="normal"
              autoComplete="email"
            />
            <TextField
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              margin="normal"
              autoComplete="current-password"
            />
            {error && (
              <Typography color="error" sx={styles.error}>
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={styles.button}
            >
              Login
            </Button>
          </form>

          {showConfirmation && (
            <Box sx={styles.confirmationBox}>
              <Typography variant="body1" gutterBottom>
                Enter the 6-digit confirmation code sent to your email:
              </Typography>
              <Grid container spacing={1}>
                {confirmationCode.map((code, index) => (
                  <Grid item xs={2} key={index}>
                    <TextField
                      value={code}
                      onChange={(e) =>
                        handleConfirmationCodeChange(index, e.target.value)
                      }
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: "center" },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Button
                onClick={handleConfirm}
                variant="contained"
                color="primary"
                sx={styles.button}
              >
                Confirm
              </Button>
              <Button onClick={handleResendEmail} sx={styles.resendButton}>
                Resend Code
              </Button>
            </Box>
          )}

          <Typography variant="body2" sx={styles.signUpLink}>
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
  },
  paper: {
    padding: 4,
    textAlign: "center",
    width: "100%",
    maxWidth: "500px",
  },
  error: {
    marginTop: 2,
  },
  button: {
    marginTop: 2,
  },
  signUpLink: {
    marginTop: 2,
  },
  successMessage: {
    marginBottom: 2,
  },
  resendButton: {
    marginTop: 1,
    display: "block",
  },
  confirmationBox: {
    marginTop: 2,
  },
};

export default Login;
