import React, { useState, useEffect } from 'react';
import { Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel, Alert } from '@mui/material';
import userService from './userService';

const ActiveUserManagement = ({ companyId }) => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userData = await userService.getUsers(companyId);
        setUsers(userData);
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Error fetching users');
      }
    };

    fetchUsers();
  }, [companyId]);

  const handleRoleChange = async (id, role) => {
    try {
      console.log('id', id, 'role', role);
      const updatedUser = await userService.updateUserRole(id, role);
      setUsers(users.map(user => user.id === id ? updatedUser : user));
    } catch (error) {
      console.error('Error updating user role:', error);
      setError('Failed to update user role. Please try again.');
    }
  };

  const handleToggleActive = async (id, active) => {
    try {
        console.log('id', id, 'active', active);
      const updatedUser = await userService.updateUserActive(id, active);
      setUsers(users.map(user => user.id === id ? updatedUser : user));
    } catch (error) {
      console.error('Error updating user status:', error);
      setError('Failed to update user status. Please try again.');
    }
  };

  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <Typography variant="h6" style={{ marginBottom: 20 }}>Manage Users</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ display: 'none' }}>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell style={{ display: 'none' }}>{user.id}</TableCell>
              <TableCell>{user.first_name} {user.last_name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    name="role"
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                  >
                    <MenuItem value="User">User</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={user.active}
                      onChange={() => handleToggleActive(user.id, !user.active)}
                      name="active"
                      color="primary"
                    />
                  }
                  label={user.active ? 'Active' : 'Inactive'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ActiveUserManagement;
