// ThemeWrapper.js
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline"; // Import CssBaseline

const theme = createTheme({
  palette: {
    primary: {
      main: "#0d47a1", // Deep blue
    },
    secondary: {
      main: "#1565c0", // Another shade of deep blue
    },
    error: {
      main: "#d32f2f", // A deep red for error
    },
    background: {
      default: "#e3f2fd", // Light blue background
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          backgroundColor: "#e3f2fd", // Light blue background
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e3a5f", // Darker deep blue for drawer
          flex: 1,
          overflow: "auto",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0d47a1", // Deep blue for AppBar
          position: "fixed",
          zIndex: 1201, // Ensure the AppBar is above the drawer
          height: 64, // Fixed height for AppBar
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 64, // Ensure Toolbar height matches AppBar
        },
      },
    },
  },
});

export default function ThemeWrapper({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
