import React, { useState } from "react";
import { Link } from "react-router-dom";
import { navigate, useNavigate } from 'react-router-dom';

import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Paper,
} from "@mui/material";
import { CognitoUserPool } from "amazon-cognito-identity-js";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userPoolId = process.env.REACT_APP_USER_POOL_ID;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const navigate = useNavigate();
  const poolData = {
    UserPoolId: userPoolId,
    ClientId: clientId,
  };

  const userPool = new CognitoUserPool(poolData);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;

    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long`;
    }
    if (!hasNumber.test(password)) {
      return "Password must contain at least 1 number";
    }
    if (!hasSpecialChar.test(password)) {
      return "Password must contain at least 1 special character";
    }
    if (!hasUpperCase.test(password)) {
      return "Password must contain at least 1 uppercase letter";
    }
    if (!hasLowerCase.test(password)) {
      return "Password must contain at least 1 lowercase letter";
    }
    return "";
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
  
    const attributeList = [
      {
        Name: 'email',
        Value: email,
      },
      {
        Name: 'given_name',
        Value: firstName,
      },
      {
        Name: 'family_name',
        Value: lastName,
      },
    ];
  
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
  
      const cognitoUser = result.user;
      console.log('User signed up successfully. Username:', cognitoUser.getUsername());
  
      // Redirect to login page with a success message
      navigate('/login', { state: { successMessage: 'Account created successfully. Please check your email for verification instructions.' } });
    });
  };

  return (
    <Container>
      <Box sx={styles.pageContainer}>
        <Container maxWidth="sm">
          <Paper elevation={3} sx={styles.paper}>
            <Typography variant="h4" component="h1" gutterBottom>
              Sign Up
            </Typography>
            {successMessage ? (
              <Typography variant="body1" sx={styles.successMessage}>
                {successMessage}
              </Typography>
            ) : (
              <form onSubmit={handleSignUp}>
                <TextField
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                  autoComplete="email"
                  disabled={isSubmitting}
                />
                <TextField
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                  autoComplete="given-name"
                  disabled={isSubmitting}
                />
                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                  autoComplete="family-name"
                  disabled={isSubmitting}
                />
                <TextField
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                  autoComplete="new-password"
                  disabled={isSubmitting}
                />
                <TextField
                  type="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                  autoComplete="new-password"
                  disabled={isSubmitting}
                />
                {passwordError && (
                  <Typography color="error" sx={styles.error}>
                    {passwordError}
                  </Typography>
                )}
                {error && (
                  <Typography color="error" sx={styles.error}>
                    {error}
                  </Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={styles.button}
                  disabled={
                    !email ||
                    !password ||
                    !firstName ||
                    !lastName ||
                    password !== confirmPassword ||
                    isSubmitting
                  }
                >
                  Sign Up
                </Button>
              </form>
            )}
            <Typography variant="body2" sx={styles.loginLink}>
              Already have an account? <Link to="/login">Login</Link>
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Container>
  );
};

const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  paper: {
    padding: 4,
    textAlign: "center",
  },
  successMessage: {
    color: "green",
    marginTop: 2,
  },
  error: {
    marginTop: 2,
  },
  button: {
    marginTop: 2,
  },
  signUpLink: {
    marginTop: 2,
  },
};

export default SignUp;
