import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_TENANT_API_URL}/users`;

const userService = {
  getUsers: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/`);
      return response.data;
    } catch (error) {
      throw new Error("Error fetching invited users");
    }
  },
  updateUserActive: async (userId, active) => {
    try {
        console.log("user_id", userId, "active", active, "API_BASE_URL", API_BASE_URL);
      const response = await axios.patch(`${API_BASE_URL}/${userId}`, {
        active: active
      });
      return response.data;
    } catch (error) {
      throw new Error("Error sending invite");
    }
  },
  updateUserRole: async (userId, role) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/${userId}`, {
        role: role
      });
      return response.data;
    } catch (error) {
      throw new Error("Error sending invite");
    }
  },
};

export default userService;
