import axios from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_CAMPAIGN_API_URL}/questions`;

const questionService = {
  getQuestions: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}`);
      return response.data;
    } catch (error) {
      throw new Error("Error fetching questions");
    }
  },  
  createQuestion: async (question) => { 
    try {
      const response = await axios.post(`${API_BASE_URL}`, question);
      return response.data;
    } catch (error) {
      throw new Error("Error creating question");
    }
  },
  updateQuestion: async (questonId, question) => {
    try{
      const response = await axios.post(`${API_BASE_URL}/${questonId}`, question);
      return response.data;
    }catch(error){
      throw new Error("Error updating question");
    }
  }
};

export default questionService;