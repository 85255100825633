import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import tenantService from "../../services/tenantService";
import PhoneInput from "../Common/PhoneInput";

const buttonStyle = {
  margin: "8px 0",
  backgroundColor: "#1976d2",
  color: "white",
  padding: "8px 16px",
  textTransform: "none",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#1565c0",
  },
};

const OrganizationPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const [openAddOrgDialog, setOpenAddOrgDialog] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgAddress1, setOrgAddress1] = useState("");
  const [orgAddress2, setOrgAddress2] = useState("");
  const [orgCity, setOrgCity] = useState("");
  const [orgState, setOrgState] = useState("");
  const [orgZip, setOrgZip] = useState("");
  const [orgPhone, setOrgPhone] = useState("");
  const [orgWebsite, setOrgWebsite] = useState("");

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const data = await tenantService.getOrganizations();
      setOrganizations(data);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const handleAddOrganization = async () => {
    if (orgName.trim() !== "") {
      const newOrg = {
        name: orgName,
        address1: orgAddress1,
        address2: orgAddress2,
        city: orgCity,
        state: orgState,
        zipcode: orgZip,
        phone: orgPhone,
        website: orgWebsite,
      };
      try {
        const data = await tenantService.createOrganization(newOrg);
        setOrganizations([...organizations, data]);
        clearForm();
        setOpenAddOrgDialog(false);
      } catch (error) {
        console.error("Error adding organization:", error);
      }
    }
  };

  const clearForm = () => {
    setOrgName("");
    setOrgAddress1("");
    setOrgAddress2("");
    setOrgCity("");
    setOrgState("");
    setOrgZip("");
    setOrgPhone("");
    setOrgWebsite("");
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Organizations
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setOpenAddOrgDialog(true)}
        sx={buttonStyle}
      >
        Add Organization
      </Button>

      <List sx={{ marginTop: 4 }}>
        {organizations.map((org) => (
          <ListItem
            key={org.id}
            divider
            button
            component={Link}
            to={{
              pathname: `/organizations/${org.id}`,
              state: { organization: org },
            }}
          >
            <ListItemText
              primary={org.name}
              secondary={`${org.address1}, ${org.city}, ${org.state} ${org.zipcode}`}
            />
          </ListItem>
        ))}
      </List>

      <Dialog
        open={openAddOrgDialog}
        onClose={() => setOpenAddOrgDialog(false)}
        fullWidth
      >
        <DialogTitle>Add a New Organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for the new organization.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Organization Name"
            type="text"
            fullWidth
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Address Line 1"
            type="text"
            fullWidth
            value={orgAddress1}
            onChange={(e) => setOrgAddress1(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Address Line 2"
            type="text"
            fullWidth
            value={orgAddress2}
            onChange={(e) => setOrgAddress2(e.target.value)}
          />
          <TextField
            margin="dense"
            label="City"
            type="text"
            fullWidth
            value={orgCity}
            onChange={(e) => setOrgCity(e.target.value)}
          />
          <TextField
            margin="dense"
            label="State"
            type="text"
            fullWidth
            value={orgState}
            onChange={(e) => setOrgState(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Zip Code"
            type="text"
            fullWidth
            value={orgZip}
            onChange={(e) => setOrgZip(e.target.value)}
          />
          <PhoneInput
            label="Phone"
            value={orgPhone}
            onChange={(e) => setOrgPhone(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Website"
            type="text"
            fullWidth
            value={orgWebsite}
            onChange={(e) => setOrgWebsite(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAddOrgDialog(false)}
            color="secondary"
            sx={buttonStyle}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddOrganization}
            color="primary"
            sx={buttonStyle}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrganizationPage;
