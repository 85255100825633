import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ThemeWrapper from './ThemeWrapper';
import { UserProvider } from './contexts/UserContext';
import Login from './components/Auth/Login';
import SignUp from './components/Auth/SignUp';
import Layout from './components/Common/Layout';
import Dashboard from './components/Dashboard/Dashboard';
import UserProfile from './components/Auth/UserProfile';
import OrganizationProfile from './components/Organization/OrganizationProfile';
import CampaignManagement from './components/Campaign/CampaignManagement';
import CampaignDetails from './components/Campaign/CampaignDetails';
import QuestionManagement from './components/Question/QuestionManagement';
import UserManagement from './components/User/UserManagement';

import AuthRoute from './AuthRoute'; // Import the custom hook
import OrganizationPage from './components/Organization/OrganizationPage';
import TemplateDashboard from './components/Templates/TemplateDashboard';

const App = () => {
  return (
    <ThemeWrapper>
      <Router>
        <UserProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />

            {/* Wrap protected routes with AuthRoute */}
            <Route path="/" element={<AuthRoute><Layout><Dashboard /></Layout></AuthRoute>} />
            <Route path="/profile" element={<AuthRoute><Layout><UserProfile /></Layout></AuthRoute>} />
            <Route path="/organizations" element={<AuthRoute><Layout><OrganizationPage /></Layout></AuthRoute>} />
            <Route path="/organizations/:organization_id" element={<AuthRoute><Layout><OrganizationProfile /></Layout></AuthRoute>} />
            <Route path="/campaign-management" element={<AuthRoute><Layout><CampaignManagement /></Layout></AuthRoute>} />
            <Route path="/create-campaign" element={<AuthRoute><Layout><CampaignDetails /></Layout></AuthRoute>} />
            <Route path="/edit-campaign/:id" element={<AuthRoute><Layout><CampaignDetails /></Layout></AuthRoute>} />
            <Route path="/templates" element={<AuthRoute><Layout><TemplateDashboard /></Layout></AuthRoute>} />
            <Route path="/question-management" element={<AuthRoute><Layout><QuestionManagement /></Layout></AuthRoute>} />
            <Route path="/user-management" element={<AuthRoute><Layout><UserManagement /></Layout></AuthRoute>} />
          </Routes>
        </UserProvider>
      </Router>
    </ThemeWrapper>
  );
};

export default App;
