import React, { useState } from 'react';
import { Container, Typography, Paper, TextField, Button, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, FormLabel, MenuItem, Select, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CampaignCreation = () => {
  const [campaign, setCampaign] = useState({
    name: '',
    applicationStartDate: '',
    applicationEndDate: '',
    matchingDate: '',
    menteeApplicants: '',
    mentorApplicants: '',
    goalMatches: '',
    inviteOnly: 'open',
    unlimitedMentee: false,
    unlimitedMentor: false,
    emails: {
      applicationInvite: { enabled: false, template: '' },
      applicationAcknowledgement: { enabled: false, template: '' },
      matchingNotification: { enabled: false, template: '' },
      nonMatched: { enabled: false, template: '' },
    }
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.includes('.')) {
      const [emailKey, emailField] = name.split('.');
      setCampaign({
        ...campaign,
        emails: {
          ...campaign.emails,
          [emailKey]: { ...campaign.emails[emailKey], [emailField]: type === 'checkbox' ? checked : value },
        }
      });
    } else {
      setCampaign({ ...campaign, [name]: type === 'checkbox' ? checked : value });
    }
  };

  const handleSubmit = () => {
    // Save the campaign (e.g., send it to the server or update state in a parent component)
    console.log('Campaign created:', campaign);
    navigate('/campaign-management');
  };

  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>Create New Campaign</Typography>
      <Paper style={{ padding: 20 }}>
        <form>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Campaign Name"
            type="text"
            fullWidth
            value={campaign.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="applicationStartDate"
            label="Application Start Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={campaign.applicationStartDate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="applicationEndDate"
            label="Application End Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={campaign.applicationEndDate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="matchingDate"
            label="Matching Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={campaign.matchingDate}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={campaign.unlimitedMentee}
                onChange={handleChange}
                name="unlimitedMentee"
              />
            }
            label="Unlimited Mentee Applicants"
          />
          {!campaign.unlimitedMentee && (
            <TextField
              margin="dense"
              name="menteeApplicants"
              label="Number of Mentee Applicants"
              type="number"
              fullWidth
              value={campaign.menteeApplicants}
              onChange={handleChange}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={campaign.unlimitedMentor}
                onChange={handleChange}
                name="unlimitedMentor"
              />
            }
            label="Unlimited Mentor Applicants"
          />
          {!campaign.unlimitedMentor && (
            <TextField
              margin="dense"
              name="mentorApplicants"
              label="Number of Mentor Applicants"
              type="number"
              fullWidth
              value={campaign.mentorApplicants}
              onChange={handleChange}
            />
          )}
          <TextField
            margin="dense"
            name="goalMatches"
            label="Number of Goal Matches"
            type="number"
            fullWidth
            value={campaign.goalMatches}
            onChange={handleChange}
          />
          <FormControl component="fieldset" style={{ marginTop: 20 }}>
            <FormLabel component="legend">Application Type</FormLabel>
            <RadioGroup
              name="inviteOnly"
              value={campaign.inviteOnly}
              onChange={handleChange}
            >
              <FormControlLabel value="open" control={<Radio />} label="Open" />
              <FormControlLabel value="invite" control={<Radio />} label="Invite Only" />
            </RadioGroup>
          </FormControl>
          <Typography variant="h6" style={{ marginTop: 20 }}>Emails</Typography>
          {['applicationInvite', 'applicationAcknowledgement', 'matchingNotification', 'nonMatched'].map((emailKey) => (
            <div key={emailKey} style={{ marginBottom: 20 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={campaign.emails[emailKey].enabled}
                    onChange={handleChange}
                    name={`${emailKey}.enabled`}
                  />
                }
                label={emailKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
              />
              {campaign.emails[emailKey].enabled && (
                <FormControl fullWidth>
                  <Select
                    name={`${emailKey}.template`}
                    value={campaign.emails[emailKey].template}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value=""><em>Select Template</em></MenuItem>
                    <MenuItem value="template1">Template 1</MenuItem>
                    <MenuItem value="template2">Template 2</MenuItem>
                    <MenuItem value="template3">Template 3</MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: 20 }}>
            Create Campaign
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default CampaignCreation;
