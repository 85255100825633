// src/services/apiService.js
import ApiClient from './apiClient';
import axios from 'axios';

const API_URL = process.env.REACT_APP_TENANT_API_URL

// Create an Axios instance with refresh token logic (as updated above)
const apiClientInstance = new ApiClient(API_URL);
const apiClient = apiClientInstance.getClient();

// Flag to prevent multiple simultaneous refresh attempts
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Request interceptor to add the Authorization header
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('id_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    // Check if the error is due to token expiration
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (error.response.data.detail === 'Token has expired') {
        if (isRefreshing) {
          // If token is already being refreshed, queue the request
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = 'Bearer ' + token;
              return apiClient(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = localStorage.getItem('refresh_token');

        return new Promise(function (resolve, reject) {
          axios
            .post(`${API_URL}/auth/refresh-token`, { refresh_token: refreshToken })
            .then(({ data }) => {
              const newAccessToken = data.access_token;
              localStorage.setItem('id_token', newAccessToken);
              apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
              originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
              processQueue(null, newAccessToken);
              resolve(apiClient(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              // Optionally, handle logout if refresh token is invalid
              localStorage.removeItem('id_token');
              localStorage.removeItem('refresh_token');
              window.location.href = '/login'; // Redirect to login page
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }

    return Promise.reject(error);
  }
);

// Health Check - GET /health
export const getHealthStatus = async () => {
  try {
    const response = await apiClient.get('/health');
    return response.data;
  } catch (error) {
    console.error('Error fetching health status:', error);
    throw error;
  }
};

// Get Current User - GET /user
export const getCurrentUser = async () => {
  try {
    const response = await apiClient.get('/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
};

// Create Organization - POST /organizations
export const createOrganization = async (organizationData) => {
  try {
    const response = await apiClient.post('/organizations', organizationData);
    return response.data;
  } catch (error) {
    console.error('Error creating organization:', error);
    throw error;
  }
};

// Get Organization by ID - GET /organizations/{organization_id}
export const getOrganizations = async (organizationId) => {
  try {
    const response = await apiClient.get(`/organizations`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching organizations`, error);
    throw error;
  }
};

// Get Organization by ID - GET /organizations/{organization_id}
export const getOrganizationById = async (organizationId) => {
  try {
    const response = await apiClient.get(`/organizations/${organizationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching organization ${organizationId}:`, error);
    throw error;
  }
};

// Get Member Details - GET /organizations/{organization_id}/members/{user_id}
export const getOrganizationMembers = async (organizationId) => {
  try {
    const response = await apiClient.get(`/organizations/${organizationId}/members`);
    console.log("Fetching members for organization:", response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching members in organization ${organizationId}:`, error);
    throw error;
  }
};

// Get Member Details - GET /organizations/{organization_id}/members/{user_id}
export const getMemberDetails = async (organizationId, userId) => {
  try {
    const response = await apiClient.get(`/organizations/${organizationId}/members/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching member ${userId} in organization ${organizationId}:`, error);
    throw error;
  }
};

// Get Member Details - GET /organizations/{organization_id}/members/{user_id}
export const getOrganizationInvitations = async (organizationId) => {
  try {
    const response = await apiClient.get(`/organizations/${organizationId}/invitations`);
    console.log("Fetching members for organization:", response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching members in organization ${organizationId}:`, error);
    throw error;
  }
};

// Create Invitation - POST /organizations/{organization_id}/invitations
export const createInvitation = async (organizationId, invitationData) => {
  try {
    const response = await apiClient.post(`/organizations/${organizationId}/invitations`, invitationData);
    return response.data;
  } catch (error) {
    console.error('Error creating invitation:', error);
    throw error;
  }
};

// Update Invitation - PUT /organizations/{organization_id}/invitations/{invitation_id}
export const updateInvitation = async (organizationId, invitationId, invitationData) => {
  try {
    const response = await apiClient.put(
      `/organizations/${organizationId}/invitations/${invitationId}`,
      invitationData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating invitation:', error);
    throw error;
  }
};

// Delete Invitation - DELETE /organizations/{organization_id}/invitations/{invitation_id}
export const deleteInvitation = async (organizationId, invitationId) => {
  try {
    const response = await apiClient.delete(
      `/organizations/${organizationId}/invitations/${invitationId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting invitation:', error);
    throw error;
  }
};

// Update Organization - PUT /organizations/{organization_id}
export const updateOrganization = async (organizationId, organizationData) => {
  try {
    const response = await apiClient.put(
      `/organizations/${organizationId}`,
      organizationData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating organization:', error);
    throw error;
  }
};

// Delete Organization - DELETE /organizations/{organization_id}
export const deleteOrganization = async (organizationId) => {
  try {
    const response = await apiClient.delete(`/organizations/${organizationId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting organization:', error);
    throw error;
  }
};

// Export all methods
export default {
  getHealthStatus,
  getCurrentUser,
  createOrganization,
  getOrganizations,
  getOrganizationById,
  getOrganizationMembers,
  getOrganizationInvitations,
  getMemberDetails,
  createInvitation,
  updateInvitation,
  deleteInvitation,
  updateOrganization,
  deleteOrganization,
};
