import React, { useState } from "react";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Card,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { updateTemplate } from "../../services/templateService";
import TemplateForm from "./TemplateForm";

// Helper function to truncate text
const truncateText = (text = "", length) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

// TemplateCard Component
const TemplateCard = ({ template, onUpdateTemplate, onDeleteTemplate }) => {
  const { id, name, template_type, message, body, subject, description, title } = template;

  const [isEditing, setIsEditing] = useState(false); // State to control edit dialog visibility
  const [loading, setLoading] = useState(false); // Loading state for delete
  const [isDeleting, setIsDeleting] = useState(false); // State to control delete confirmation visibility

  // Function to handle the update of a template
  const handleUpdateTemplate = async (updatedTemplate) => {
    try {
      const updated = await updateTemplate(id, updatedTemplate);
      onUpdateTemplate(updated); // Update the template in the parent component
      setIsEditing(false); // Close the edit dialog
    } catch (error) {
      console.error("Error updating template:", error);
    }
  };

  // Function to handle template deletion
  const handleDeleteTemplate = async () => {
    setLoading(true);
    try {
      setIsDeleting(false); // Close the delete confirmation dialog
      await onDeleteTemplate(template_type, id); // Call the parent delete handler
    } catch (error) {
      console.error(`Error deleting template ${id}:`, error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // Function to render the correct icon and title based on template type
  const renderIconAndTitle = () => {
    if (template_type === "SMS") {
      return (
        <Box display="flex" alignItems="center">
          <ListItemIcon>
            <SmsIcon />
          </ListItemIcon>
          <Typography variant="h6">{name}</Typography>
        </Box>
      );
    }
    if (template_type === "EMAIL") {
      return (
        <Box display="flex" alignItems="center">
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <Typography variant="h6">{name}</Typography>
        </Box>
      );
    }
    if (template_type === "EVENT") {
      return (
        <Box display="flex" alignItems="center">
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <Typography variant="h6">{name}</Typography>
        </Box>
      );
    }
  };

  // Conditional rendering based on template type
  const renderTemplateDetails = () => {
    if (template_type === "SMS") {
      return (
        <Typography variant="body1">
          <strong>Message:</strong> {message}
        </Typography>
      );
    }
    if (template_type === "EMAIL") {
      return (
        <>
          <Typography variant="body1">
            <strong>Subject:</strong> {subject}
          </Typography>
          <Typography variant="body2">
            <strong>Body:</strong> {truncateText(body, 50)}
          </Typography>
        </>
      );
    }
    if (template_type === "EVENT") {
      return (
        <>
          <Typography variant="body1">
            <strong>Title:</strong> {title}
          </Typography>
          <Typography variant="body2">
            <strong>Description:</strong> {truncateText(description, 50)}
          </Typography>
        </>
      );
    }
  };

  return (
    <Card variant="outlined" sx={{ position: "relative", padding: "16px" }}>
      <input type="hidden" value={id} /> {/* Hidden ID */}
      <Box mt={2}>
        {/* Render icon and title */}
        {renderIconAndTitle()}
        {/* Render additional details based on template type */}
        <Box mt={1}>{renderTemplateDetails()}</Box>
      </Box>

      {/* Edit and Delete buttons */}
      <Box sx={{ position: "absolute", top: 8, right: 8 }}>
        {/* Edit Button */}
        <IconButton aria-label="edit" onClick={() => setIsEditing(true)}>
          <EditIcon />
        </IconButton>

        {/* Delete Button */}
        <IconButton
          aria-label="delete"
          onClick={() => setIsDeleting(true)} // Open delete confirmation dialog
          disabled={loading}
        >
          <DeleteIcon />
        </IconButton>
      </Box>

      {/* Edit Dialog */}
      <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
        <DialogTitle>Edit Template</DialogTitle>
        <DialogContent>
          <TemplateForm
            template={template} // Pass the current template data for editing
            onAddTemplate={handleUpdateTemplate} // Reuse the form for updating
            onClose={() => setIsEditing(false)} // Close the dialog after update
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleting} onClose={() => setIsDeleting(false)}>
        <DialogTitle>Delete Template</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this template?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleting(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteTemplate}
            color="secondary"
            disabled={loading}
          >
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default TemplateCard;
