import React, { useState } from "react";
import { Button, TextField, FormControl, Select, MenuItem, CircularProgress } from "@mui/material";
import { createTemplate } from "../../services/templateService";

const TemplateForm = ({ onAddTemplate, template = null, onClose }) => {
  const [templateType, setTemplateType] = useState(template?.template_type || "SMS"); // Default type is SMS or from template
  const [name, setName] = useState(template?.name || "");
  const [message, setMessage] = useState(template?.message || ""); // For SMS
  const [subject, setSubject] = useState(template?.subject || ""); // For Email
  const [body, setBody] = useState(template?.body || ""); // For Email
  const [title, setTitle] = useState(template?.title || ""); // For Event
  const [description, setDescription] = useState(template?.description || ""); // For Event
  const [loading, setLoading] = useState(false); // Loading state for button

  // Basic validation to ensure name and necessary fields are filled in
  const isFormValid = () => {
    if (!name) return false;
    if (templateType === "SMS" && !message) return false;
    if (templateType === "EMAIL" && (!subject || !body)) return false;
    if (templateType === "EVENT" && (!title || !description)) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      alert("Please fill out all required fields.");
      return;
    }
    setLoading(true); // Show loading state

    let newTemplate;
    if (templateType === "SMS") {
      newTemplate = { name, template_type: templateType, message };
    } else if (templateType === "EMAIL") {
      newTemplate = { name, template_type: templateType, subject, body };
    } else if (templateType === "EVENT") {
      newTemplate = { name, template_type: templateType, title, description };
    }

    try {
      // Call the service to add or update the template
      const addedTemplate = await createTemplate(newTemplate);
      
      // Pass the added or updated template to the dashboard
      onAddTemplate(addedTemplate);

      // Close the form after successful submission
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error("Failed to add or update template:", error);
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <Select
          labelId="template-type-label"
          value={templateType}
          onChange={(e) => setTemplateType(e.target.value)}
          disabled={Boolean(template)} // Disable changing type if editing
        >
          <MenuItem value="SMS">SMS</MenuItem>
          <MenuItem value="EMAIL">Email</MenuItem>
          <MenuItem value="EVENT">Event</MenuItem>
        </Select>
      </FormControl>

      <TextField
        fullWidth
        margin="normal"
        label="Template Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />

      {templateType === "SMS" && (
        <TextField
          fullWidth
          margin="normal"
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
      )}

      {templateType === "EMAIL" && (
        <>
          <TextField
            fullWidth
            margin="normal"
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
          />
        </>
      )}

      {templateType === "EVENT" && (
        <>
          <TextField
            fullWidth
            margin="normal"
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </>
      )}

      <Button type="submit" variant="contained" color="primary" disabled={loading}>
        {loading ? <CircularProgress size={24} /> : template ? "Update Template" : "Create Template"}
      </Button>
    </form>
  );
};

export default TemplateForm;
