// src/services/templateService.js
import ApiClient from './apiClient';

const API_URL = process.env.REACT_APP_TENANT_API_URL;

// Create an instance of ApiClient for template service
const apiClientInstance = new ApiClient(API_URL);
const apiClient = apiClientInstance.getClient();

// Template Management API Methods

// Get all templates owned by the current user
export const getOwnedTemplates = async () => {
  try {
    const response = await apiClient.get('/templates');
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching owned templates:', error);
    throw error;
  }
};

// Create a new template
export const createTemplate = async (templateData) => {
  try {
    const response = await apiClient.post('/templates', templateData);
    return response.data;
  } catch (error) {
    console.error('Error creating template:', error);
    throw error;
  }
};

// Update an existing template
export const updateTemplate = async (templateId, templateData) => {
  try {
    const response = await apiClient.put(`/templates/${templateId}`, templateData);
    return response.data;
  } catch (error) {
    console.error(`Error updating template ${templateId}:`, error);
    throw error;
  }
};

// Delete a template
export const deleteTemplate = async (templateType, templateId) => {
  try {
    const response = await apiClient.delete(`/templates/${templateType}/${templateId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting template ${templateId}:`, error);
    throw error;
  }
};


// Export all methods in one object
export default {
  getOwnedTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate
};
