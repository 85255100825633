import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, TextField, IconButton, Box, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "../Common/PhoneInput";
import tenantService from "../../services/tenantService";

const OrganizationDetails = ({ organization_id }) => {
  const [editMode, setEditMode] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgAddress1, setOrgAddress1] = useState("");
  const [orgAddress2, setOrgAddress2] = useState("");
  const [orgCity, setOrgCity] = useState("");
  const [orgState, setOrgState] = useState("");
  const [orgZip, setOrgZip] = useState("");
  const [orgPhone, setOrgPhone] = useState("");
  const [orgWebsite, setOrgWebsite] = useState("");

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const organization = await tenantService.getOrganizationById(organization_id);
        console.log("Fetched organization data:", organization); // Log full response
        setOrgName(organization.name || "");
        setOrgAddress1(organization.address1 || "");
        setOrgAddress2(organization.address2 || "");
        setOrgCity(organization.city || "");
        setOrgState(organization.state || "");
        setOrgZip(organization.zipcode || "");
        setOrgPhone(organization.phone || "");
        setOrgWebsite(organization.website || "");
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };
  
    fetchOrganizationDetails();
  }, [organization_id]);

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handlePhoneChange = (e) => {
    const sanitizedPhone = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setOrgPhone(sanitizedPhone);
  };

  const handleSave = async () => {
    try {
      console.log("Data to be saved:", {
        name: orgName,
        address1: orgAddress1,
        address2: orgAddress2,
        city: orgCity,
        state: orgState,
        zipcode: orgZip,
        phone: orgPhone,
        website: orgWebsite,
      }); // Log to inspect values being sent

      await tenantService.updateOrganization(organization_id, {
        name: orgName,
        address1: orgAddress1,
        address2: orgAddress2,
        city: orgCity,
        state: orgState,
        zipcode: orgZip,
        phone: orgPhone,
        website: orgWebsite,
      });
      setEditMode(false);
    } catch (error) {
      console.error("Error saving organization details:", error);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Organization Details</Typography>
          <Box>
            {editMode ? (
              <>
                <IconButton onClick={handleSave} color="primary">
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCancel} color="secondary">
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleEditToggle}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box mt={2}>
          {editMode ? (
            <>
              <TextField
                label="Organization Name"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)} // Ensure state is updated on change
                fullWidth
                margin="normal"
              />
              <TextField
                label="Address Line 1"
                value={orgAddress1}
                onChange={(e) => setOrgAddress1(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Address Line 2"
                value={orgAddress2}
                onChange={(e) => setOrgAddress2(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="City"
                value={orgCity}
                onChange={(e) => setOrgCity(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="State"
                value={orgState}
                onChange={(e) => setOrgState(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Zip Code"
                value={orgZip}
                onChange={(e) => setOrgZip(e.target.value)}
                fullWidth
                margin="normal"
              />
              <PhoneInput
                label="Phone"
                value={orgPhone}
                onChange={handlePhoneChange}
              />
              <TextField
                label="Website"
                value={orgWebsite}
                onChange={(e) => setOrgWebsite(e.target.value)}
                fullWidth
                margin="normal"
              />
            </>
          ) : (
            <>
              <Typography variant="body1">
                <strong>Organization Name:</strong> {orgName}
              </Typography>
              <Typography variant="body1">
                <strong>Address:</strong> {orgAddress1} {orgAddress2}, {orgCity}, {orgState} {orgZip}
              </Typography>
              <Typography variant="body1">
                <strong>Phone:</strong> {orgPhone}
              </Typography>
              <Typography variant="body1">
                <strong>Website:</strong> <a href={orgWebsite}>{orgWebsite}</a>
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrganizationDetails;
